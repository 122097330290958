import styled from 'styled-components';

export const StyledFooter = styled.div`
    background-color: #84908f;
    color: #fff;
    padding-top: 36px;
    padding-bottom: 44px;
`

export const Copyright = styled.div`
    font-size: 13px;
    opacity: .5;
    text-align: right;
    font-weight: 300;

    a {
        color: #fff;
    }

    @media (max-width: 640px) {
        text-align: center;
    }
`

export const FooterNavRow = styled.div`
    display: flex;

    @media (max-width: 640px) {
        flex-direction: column;
    }
`

export const FooterNavCol = styled.div`
    min-width: 200px;

    & > div{
        padding: 4px 0;
    }

    h6{
        margin-bottom: 16px;
    }

    a{
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        opacity: .6;
    }

    @media (max-width: 640px) {
        text-align: center;
        margin-bottom: 32px;
    }
`

export const StyledFooterInner = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 640px) {
        flex-direction: column;
    }
`

export const SocialLinks = styled.div`
    display: flex;
    justify-content: end;
    gap: 16px;

    @media (max-width: 640px) {
        justify-content: center;
    }
`

export const StyledFooterBottom = styled.div`
    text-align: right;

    @media (max-width: 640px) {
        text-align: center;
        margin-top: 44px;
    }
`