import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'

export const SavingCalculation = () => {
  return (
    <>
      <Header />
      <ContentPage>
        <Container>
          <h1>How is the 10.85% savings on the website calculated?</h1>
          <p>The previous articles introduced how to read and understand the electricity bill. The displayed electricity price of 500/1000/2000kWh on the Facts Label does not represent the real electricity bill, and sometimes could be quite misleading. Therefore when comparing different electricity plans, it is difficult to make a fair and reasonable comparison.</p>
          <p>Let’s introduce how eIQshopping uses patented technology to compare apples to apples to help consumers choose the most suitable electricity plan.</p>
          <p><b>1. Total Est. Rate/kWh:</b> The Total Est. Rate/kWh takes into account your electricity usage patterns, tiered rates, transmission and distribution charges, basic service charges, bill deductions, rebates, and other fixed charges. This is completely different from the displayed electricity price of 500/1000/2000kWh. It is calculated based on the actual electricity consumption of each consumer and brought into different electricity plans, which is the closest to the real electricity rate consumers will be paying.</p>
          <p><b>2. Average market price:</b> First of all, the average retail electricity market price is not the same as the wholesale electricity price. Due to the different contract periods of retail energy plans, different basic electricity charges, tiered rates, transmission and distribution charges, and the different electricity consumption of each household, a unified average price in the retail electricity market does not exist. eIQshopping calculates the Total Est. Rate/kWh for different electricity plans based on the actual electricity consumption of each user. Then, the plans are classified according to different lengths such as 6 months, 12 months and 24 months, and finally the average market price of different contract periods is calculated.</p>
          <p><b>3. How to interpret the 10.85% savings:</b> With these average prices, we can make a fair side-by-side comparison of the Total Est. Rate/kWh for a certain electricity plan within the market. Suppose, based on your historical electricity usage data, the Total Est. Rate/kWh for option A with a 12-month contract period is $10.5, which is 30% lower than the market average, and the Total Est. Rate/kWh for option B with a 3-month contract period is $9.5, which is 5% higher than the market average. Which one should you choose? The answer is that although the Total Est. Rate/kWh of option B is lower than that of option A, it is higher than the average market price and clearly not a good choice. If you are willing to choose a short-term 3-month plan, then there must be a lower and more cost-effective price than B in the market. Conversely, if you want to choose a longer term plan, then Plan A, which is 30% lower than the average market price, may be a good choice.</p>
          <p>Finally, consumers can also compare the electricity bills that households are currently paying. If energy market prices keep skyrocketing, there is a good chance that you will have to choose the cheapest of all the more expensive options when switching to a new one. And if energy market prices keep going down, there are plenty of money-saving options when switching to a new plan, and eIQshopping can help you find the best one.</p>
        </Container>
      </ContentPage>
    </>
  )
}
