import { API_URL, ACC_KEY } from '../config'

export const saveEnrollment = async (enrollmentId, restFields, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    if (enrollmentId) formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    for (let i in restFields) {
        formdata.append(i, restFields[i])
    }
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/save`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentRecord = async (enrollmentId, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/retrieve`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const confirmEnrollment = async (enrollmentId, specialPro, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    for (const key in specialPro) {
        if (specialPro[key]) formdata.append(key, specialPro[key])
    }

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/submit`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentInputs = async (providerId) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('provider_id', providerId)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/input`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getBlackoutDate = async (product, zipcode, utility_code) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('product', product)
    formdata.append('zipcode', zipcode)
    formdata.append('utility_code', utility_code)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/blackout_date`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchEnrollSmartHomeBundle = async (fname, lname, address, city, state, zipcode, phone, email, enrollmentId) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer c5fb349da68ab8094c07a799e99e09ce`)

    const formdata = new FormData()
    formdata.append('first_name', fname)
    formdata.append('last_name', lname)
    formdata.append('address', address)
    formdata.append('city', city)
    formdata.append('state', state)
    formdata.append('zip', zipcode)
    formdata.append('phone', phone)
    formdata.append('email', email)
    formdata.append('enrollment_id', enrollmentId)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `https://dev.powerlego.com/ApiGateway/v2/s24_lead_submit/submit_enrollment`

    const response = await fetch(url, requestOptions)
    return response.json()
}