import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { Form } from 'react-bootstrap';
import { Button } from '../../styles/Button.styled';
import { useLocation } from 'react-router-dom';
import { CheckboxItem } from '../../checkbox-item';
import qs from 'qs';
import type { TduGetInfoProps } from '../../../types';
import { getConsentId, getUsageViaTdu } from '../../../api/tdu';
import { RollingTextLoading } from '../../rolling-text-loading';
import { fetchEstUsage } from '../../../api/api';

export const TduGetInfo: React.FC<TduGetInfoProps> = ({
    handleBack,
    hideBack,
    showSmtView,
    showMyaccountView,
    continueWithForecast,
    handleSetUsage
}) => {
    const { t } = useTranslation('usage');
    const location = useLocation();
    const [ validated, setValidated ] = useState(false);
    const [ showConsentError, setShowConsentError ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ fname, setFname ] = useState('');
    const [ lname, setLname ] = useState('');
    const [ submitting, setSubmitting ] = useState(false);
    const [ isAgree, setIsAgree ] = useState(false);
    const timer: any = useRef(null);
    
    useEffect(() => {
        return () => {
            if (timer) clearTimeout(timer.current);
        }
    }, [])

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const { esiid, zipcode, utcode } = qs.parse(location.search, { ignoreQueryPrefix: true });
        const form = evt.currentTarget as HTMLInputElement;
        setValidated(true);
        if (!isAgree) {
            setShowConsentError(true);
            return;
        }
        if (!form.checkValidity() || !esiid || !zipcode || submitting) return;

        setSubmitting(true);
        getConsentId(
            esiid,
            email,
            '', //phone
            zipcode,
            `${fname} ${lname}`,
            `${utcode}`
        ).then(res => {
            if (res && res.response && res.response.consent_id) {
                if (res.response.email === 1) {
                    getPredictedUsage();
                    return;
                }
                fetchUsageWithTimeout(res.response.consent_id);
            }
            else {
                getPredictedUsage();
            }
        })
    }

    const fetchUsageWithTimeout = (consent_id: string) => {
        const controller = new AbortController();
        timer.current = setTimeout(() => controller.abort(), 120000);
        fetchUsage(consent_id, controller);
    };

    const fetchUsage = (consent_id: string, controller: any) => {
        getUsageViaTdu(
            consent_id,
            {signal: controller.signal}
        ).then(res => {
            if (res) {
                if (parseInt(res.status) === 1) {
                    clearTimeout(timer.current);
                    const data = res.data;
                    let usageArr: string[] = [];
                    data.forEach((item: {
                        'actual_kwh': string
                    }) => {
                        usageArr.push(item['actual_kwh']);
                    });

                    handleSetUsage(usageArr);
                }
                if (parseInt(res.status) === 0) {
                    setTimeout(() => fetchUsage(consent_id, controller), 10000);
                }
            }
            else {
                getPredictedUsage();
                clearTimeout(timer.current);
            }
        }).catch(() => {
            getPredictedUsage();
            clearTimeout(timer.current);
        })
    }

    const getPredictedUsage = async () => {
        const { address, zipcode, city, state } = qs.parse(location.search, { ignoreQueryPrefix: true });

        fetchEstUsage(address, city, state, zipcode).then((res) => {
            if (res && res.status === 1 && res.usages) {
                console.log(Object.values(res.usages))
                handleSetUsage(Object.values(res.usages), true);
            }
        })
    }

    return (
        <div className="position-relative">
            <h2>{t('We are almost there with your usage data')}</h2>
            <p>{t('You must grant us permission to your usage data by providing information below')}</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>{t('First Name')} *</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        onChange={evt => setFname(evt.target.value)}
                        value={fname}
                        disabled={submitting}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('Last Name')} *</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        onChange={evt => setLname(evt.target.value)}
                        value={lname}
                        disabled={submitting}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('Email')} *</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        type="email"
                        onChange={evt => setEmail(evt.target.value)}
                        value={email}
                        disabled={submitting}
                    />
                </Form.Group>
                <div className="checkbox-row">
                    <CheckboxItem
                        label={
                            <Trans i18nKey="usage:tduTos">
                                I authorize my utility company to grant eIQshopping access to the information as stated in the <a href='/files/Standard-Letter-of-Authorization-for-Request-of-Historical-Usage-Information-English-.pdf' target="_blank" rel="noreferrer">Letter of Authorization</a>
                            </Trans>
                        }
                        value="1"
                        key="0"
                        checked={isAgree}
                        handleClick={() => {
                            setShowConsentError(false);
                            setIsAgree(!isAgree)
                        }}
                        disabled={submitting}
                        error={showConsentError}
                    />
                </div>
                <Form.Group className="mb-0 d-flex justify-content-end mt-5">
                    {
                        !hideBack &&
                        <Button type="button" variant="secondary" disabled={submitting} className="me-4" onClick={() => { handleBack() }}>
                            {t('Back')}
                        </Button>
                    }
                    <Button type="submit" disabled={submitting} data-test-id="cta">{t('Continue')}</Button>
                </Form.Group>
            </Form>
            <RollingTextLoading
                text={[
                    t('This process may take upto 2 minutes', {ns:'common'}),
                    t('We are connecting with the utility company...', {ns:'common'}),
                    t("We are retrieving your usage data from TDU's database...", {ns:'common'}),
                    t('Analyzing data...', {ns:'common'}),
                    t('Be patient while we are comparing plans...', {ns:'common'}),
                    t('We are pulling your personalized electricity plan recommendations', {ns:'common'}),
                    t('Start saving in just a few seconds!', {ns:'common'}),
                    t('We are almost there!', {ns:'common'})
                ]}
                show={submitting}
            />
        </div>
    )
}
